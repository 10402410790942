export const langues = {
  EN: {
    message: {
      hello: "Hello",
      banner: {
        titre1: "Welcome to DELATRUST",
        titre2: "Accounting Firm in Luxembourg",
        pourquoi: "Why choose DELATRUST?",
        plus: "More",
        pourquoi1:
          "DELATRUST specializes in financial management, offering services such as accounting, auditing, taxation, and asset management with recognized expertise.",
        apropos: "Regulatory Compliance",
        apropos1:
          "As a fiduciary company, DELATRUST ensures that all financial operations comply with current laws and regulations, thereby minimizing risks for its clients.",
        apropos2: "Personalized Advice",
        apropos3:
          "DELATRUST offers personalized advice based on the specific needs of each client, whether individuals, SMEs, or large corporations.",
        apropos4: "Reliability and Integrity",
        apropos5:
          "DELATRUST is chosen for its reliability and integrity, essential qualities in the management of funds and assets on behalf of clients.",
        apropos6: "Announcement Space",
      },
      menu: {
        menu1: "Home",
        menu2: "About Us",
        menu3: "Services",
        menu4: "Contact",
        menu5: "Client Area",
        menu6: {
          sousmenu1: "Who We Are",
          sousmenu2: "Our Objectives",
          sousmenu3: "Our Experts",
          sousmenu4: "Our Offices",
        },
      },
      apropos: {
        "apropos1": "About Us",
        "apropos2": 
          "DELATRUST is an innovative Luxembourg-based firm specializing in accounting and tax expertise, as well as financial management. We offer solutions for tax optimization, accounting, auditing, and asset management. We pay particular attention to exploring and leveraging business opportunities between Europe and Africa. In this regard, we are committed to providing tailored solutions that perfectly meet the specific needs of each of our clients.",
        "apropos3": "Navigate with confidence in an increasingly complex economic environment, thanks to our expertise and personalized solutions!",
        "valeur": {
          "valeur": "Our Values",
          "valeur1": "Integrity and Confidentiality",
          "valeur2": 
            "We guarantee impeccable professional ethics and rigorously protect the confidentiality of our clients' information.",
          "valeur3": "Innovation and Excellence",
          "valeur4": 
            "We integrate innovation into our solutions while maintaining high-quality standards, continuously training to stay up to date.",
          "valeur5": "Commitment and Personalized Client Service",
          "valeur6": 
            "We offer dedicated and responsive support, with tailored solutions adapted to the specific needs of each client.",
          "valeur7": "International Openness",
          "valeur8": 
            "We promote international collaboration, particularly between Europe and Africa, to seize global business opportunities."
        },
        mission: {
          "mission": "Missions & Visions",
          "mission1": "Accounting, Tax, and Optimization Expertise",
          "mission2": 
            "We ensure accounting, provide tax advisory services, and propose tailored tax optimization strategies to ensure compliance and maximize the tax benefits for our clients.",
          "mission3": "Financial and Asset Management",
          "mission4": 
            "We offer personalized financial and administrative management, anticipate risks, and optimize business performance, as well as the management and valuation of assets.",
          "mission5": "Audit and Certification",
          "mission6": 
            "We conduct legal and contractual audits to ensure the transparency and reliability of financial statements.",
          "mission7": "Business Creation, Restructuring, and Internationalization",
          "mission8": 
            "We assist in the creation and restructuring of businesses, leveraging business opportunities, particularly between Europe and Africa, to foster innovative economic exchanges."
        }
        ,
      },
      service: {
        service1: "Our Services",
        service2: "",
        service3: "Accounting",
        service4:
          "We provide comprehensive accounting services, including managing accounting entries, preparing financial statements, and producing detailed financial reports. We ensure rigorous bookkeeping to guarantee clear visibility of your finances.",
        service5: "Audit",
        service6:
          "Our audit services are designed to assess the accuracy and compliance of your financial statements. We conduct internal and external audits to identify risks, improve processes, and ensure transparency and reliability in your financial operations.",
        service7: "Taxation",
        service8:
          "DELATRUST offers tax advisory services to optimize your tax situation and ensure compliance with tax obligations. We help you plan and manage your tax returns, as well as identify tax savings opportunities.",
        service9: "Financial Advisory",
        service10:
          "Our financial advisors provide strategic recommendations to improve your financial health. We assist in financial planning, risk management, and developing strategies to achieve your long-term financial goals.",
        service11: "Legal and Fiduciary Services",
        service12:
          "We offer legal and fiduciary services to manage the legal and administrative aspects of your affairs. This includes the creation of legal structures, estate management, and preparation of legal documents.",
        service13: "Dispute Resolution Assistance",
        service14:
          "In the event of a financial dispute, we provide specialized assistance to help you resolve conflicts. We offer advice and representation to protect your financial interests.",
      },
      contact: {
        contact1: "Contact",
        contact2: "Location",
        contact3: "Call Us",
        contact4: "Send Us an Email",
        contact5: "First and Last Name",
        contact6: "Email",
        contact7: "Subject",
        contact8: "Message",
        contact9: "Processing...",
        contact10: "Your message has been sent. Thank you!",
        contact11: "Send",
      },
      expert: {
        expert1: {
          nom: "Mawo KOKOU",
          titreMadal: "Message from the Director",
          fonction: "Director of DELATRUST",
          titre: "a Luxembourg-based accounting and tax firm",
          detail:
            "<br /> DELATRUST, a Luxembourg-based accounting and tax firm, stands out with its innovative services in accounting, taxation, and financial and administrative management.<br />Certified Accountant and graduated in Business Management, Mawo joins DELATRUST with in-depth expertise and a strategic vision, particularly regarding the exploration and exploitation of growing opportunities in African markets. Under his leadership, DELATRUST is determined to strengthen its international presence by offering tailored solutions that meet the specific needs of businesses interested in Luxembourg and African markets.<br />With a special focus on innovation and value creation, Mawo is committed to guiding DELATRUST towards new horizons, providing high-quality services to our clients while exploring the many opportunities offered by international markets, especially African markets. This strategic focus will enable DELATRUST to play a key role in supporting businesses facing the challenges and opportunities of a rapidly expanding market.<br /> We are confident that Mawo will make a significant contribution to our firm and lead our team to new successes.",
        },
      },
      cabinet: {
        cabinet1: {
          nom: "DELATRUST CANADA",
          dirigeant: "Manager: Mawo KOKOU",
          adresse: "Address: ",
        },
        cabinet2: {
          nom: "DELATRUST FRANCE",
          dirigeant: "Manager: Kokou TINO",
          adresse: "Address: ",
        },
        cabinet3: {
          nom: "DELATRUST BELGIUM",
          dirigeant: "Manager: Mawo KOKOU",
          adresse: "Address: ",
        },
      },
      footer: {
        footer1: "Phone",
        footer2: "Email",
        footer3: "Useful Links",
        footer4: "Home",
        footer5: "About Us",
        footer6: "Services",
        footer7: "Terms of Use",
        footer8: "Privacy Policy",
        footer9: "Establishment Authorization: 10170146/0",
      },
      info: [
        {
          title:
            "December 31: Deadline for filing the Income Tax, Commercial Tax, and Wealth Tax Declaration for municipalities",
          content:
            "Remember that the tax declaration must be submitted to the competent tax office before December 31 of the year following the relevant tax year. Don’t delay in preparing your documents. Contact us to learn more!",
        },
        {
          title: "July 31: Deadline for filing the annual accounts",
          content:
            "Annual accounts must be filed within one month of their approval, and no later than 7 months after the end of the fiscal year for individual traders or after the close of the fiscal year.",
        },
        {
          title:
            "Before March 1 or May 1: Deadline for filing the annual VAT return",
          content:
            "The deadline for filing the annual VAT return depends on the total turnover excluding tax, unless a different decision is made by the Administration of Registration and Domains (AED). Contact us to learn more!",
        },
        {
          title: "Tax obligations in Luxembourg",
          content:
            "The creation and management of a business in Luxembourg are subject to various taxes and fiscal obligations, governed by three main administrations: the Administration of Direct Contributions (ACD), ",
        },
      ],
      
    },
  },
  DE: {
    message: {
      hello: "Guten Tag",
      banner: {
        titre1: "Willkommen bei DELATRUST",
        titre2: "Steuerberatungskanzlei in Luxemburg",
        pourquoi: "Warum DELATRUST wählen?",
        plus: "Mehr",
        pourquoi1:
          "DELATRUST ist auf Finanzmanagement spezialisiert und bietet Dienstleistungen wie Buchhaltung, Wirtschaftsprüfung, Steuerberatung und Vermögensverwaltung mit anerkannter Expertise an.",
        apropos: "Regulatorische Konformität",
        apropos1:
          "Als Treuhandgesellschaft stellt DELATRUST sicher, dass alle Finanzoperationen den geltenden Gesetzen und Vorschriften entsprechen, um Risiken für die Kunden zu minimieren.",
        apropos2: "Personalisierte Beratung",
        apropos3:
          "DELATRUST bietet maßgeschneiderte Beratung, die auf die spezifischen Bedürfnisse jedes Kunden abgestimmt ist, sei es für Privatpersonen, KMUs oder große Unternehmen.",
        apropos4: "Zuverlässigkeit und Integrität",
        apropos5:
          "DELATRUST wird für seine Zuverlässigkeit und Integrität gewählt, wesentliche Qualitäten bei der Verwaltung von Fonds und Vermögenswerten im Auftrag von Kunden.",
        apropos6: "Ankündigungsbereich",
      },
      menu: {
        menu1: "Startseite",
        menu2: "Über uns",
        menu3: "Dienstleistungen",
        menu4: "Kontakt",
        menu5: "Kundenbereich",
        menu6: {
          sousmenu1: "Wer wir sind",
          sousmenu2: "Unsere Ziele",
          sousmenu3: "Unsere Experten",
          sousmenu4: "Unsere Büros",
        },
      },
      apropos: {
        "apropos1": "Über uns",
        "apropos2": 
          "DELATRUST ist eine innovative luxemburgische Kanzlei, die auf Buchhaltungs- und Steuerexpertise sowie auf Finanzmanagement spezialisiert ist. Wir bieten Lösungen zur Steueroptimierung, Buchhaltung, Prüfung und Vermögensverwaltung an. Besonderes Augenmerk legen wir auf die Erschließung und Wertschöpfung von Geschäftsmöglichkeiten zwischen Europa und Afrika. In diesem Sinne verpflichten wir uns, maßgeschneiderte Lösungen anzubieten, die perfekt auf die spezifischen Bedürfnisse jedes einzelnen Kunden zugeschnitten sind.",
        "apropos3": "Navigieren Sie mit Vertrauen in einer zunehmend komplexen Wirtschaftswelt, dank unserer Expertise und unserer maßgeschneiderten Lösungen!",
        "valeur": {
          "valeur": "Unsere Werte",
          "valeur1": "Integrität und Vertraulichkeit",
          "valeur2": 
            "Wir garantieren eine tadellose berufliche Ethik und schützen rigoros die Vertraulichkeit der Informationen unserer Kunden.",
          "valeur3": "Innovation und Exzellenz",
          "valeur4": 
            "Wir integrieren Innovation in unsere Lösungen und halten dabei hohe Qualitätsstandards aufrecht, indem wir uns kontinuierlich weiterbilden, um auf dem neuesten Stand zu bleiben.",
          "valeur5": "Engagement und maßgeschneiderter Kundenservice",
          "valeur6": 
            "Wir bieten engagierte und reaktionsschnelle Unterstützung mit maßgeschneiderten Lösungen, die auf die spezifischen Bedürfnisse jedes Kunden zugeschnitten sind.",
          "valeur7": "Internationale Offenheit",
          "valeur8": 
            "Wir fördern die internationale Zusammenarbeit, insbesondere zwischen Europa und Afrika, um globale Geschäftsmöglichkeiten zu nutzen."
        },
        mission: {
          "mission": "Missionen & Visionen",
          "mission1": "Buchhaltungs-, Steuer- und Optimierungsexpertise",
          "mission2": 
            "Wir gewährleisten die Buchhaltung, bieten Steuerberatung an und entwickeln maßgeschneiderte Strategien zur Steueroptimierung, um die Compliance sicherzustellen und die steuerlichen Vorteile unserer Kunden zu maximieren.",
          "mission3": "Finanz- und Vermögensverwaltung",
          "mission4": 
            "Wir bieten eine maßgeschneiderte Finanz- und Verwaltungsverwaltung an, antizipieren Risiken und optimieren die Unternehmensleistung sowie die Verwaltung und Wertsteigerung von Vermögenswerten.",
          "mission5": "Prüfung und Zertifizierung",
          "mission6": 
            "Wir führen gesetzliche und vertragliche Prüfungen durch, um die Transparenz und Zuverlässigkeit der Finanzberichte zu gewährleisten.",
          "mission7": "Unternehmensgründung, -restrukturierung und Internationalisierung",
          "mission8": 
            "Wir begleiten die Gründung und Restrukturierung von Unternehmen, indem wir Geschäftsmöglichkeiten bewerten, insbesondere zwischen Europa und Afrika, um innovative wirtschaftliche Austauschbeziehungen zu fördern."
        }
        ,
      },
      service: {
        service1: "Unsere Dienstleistungen",
        service2: "",
        service3: "Buchhaltung",
        service4:
          "Wir bieten umfassende Buchhaltungsdienstleistungen an, einschließlich der Verwaltung von Buchungseinträgen, der Erstellung von Finanzberichten und der Produktion detaillierter Finanzberichte. Wir sorgen für eine sorgfältige Buchführung, um eine klare Sicht auf Ihre Finanzen zu gewährleisten.",
        service5: "Wirtschaftsprüfung",
        service6:
          "Unsere Wirtschaftsprüfungsdienste sind darauf ausgelegt, die Genauigkeit und Compliance Ihrer Finanzberichte zu bewerten. Wir führen interne und externe Audits durch, um Risiken zu identifizieren, Prozesse zu verbessern und Transparenz und Zuverlässigkeit Ihrer finanziellen Operationen zu gewährleisten.",
        service7: "Steuerberatung",
        service8:
          "DELATRUST bietet steuerliche Beratung, um Ihre steuerliche Situation zu optimieren und die Einhaltung der steuerlichen Verpflichtungen sicherzustellen. Wir helfen Ihnen bei der Planung und Verwaltung Ihrer Steuererklärungen sowie bei der Identifizierung von Steuerersparungsmöglichkeiten.",
        service9: "Finanzberatung",
        service10:
          "Unsere Finanzberater bieten strategische Empfehlungen zur Verbesserung Ihrer finanziellen Gesundheit. Wir unterstützen Sie bei der Finanzplanung, Risikomanagement und Entwicklung von Strategien zur Erreichung Ihrer langfristigen finanziellen Ziele.",
        service11: "Rechts- und Treuhanddienste",
        service12:
          "Wir bieten rechtliche und treuhänderische Dienste zur Verwaltung der rechtlichen und administrativen Aspekte Ihrer Angelegenheiten an. Dazu gehören die Erstellung von Rechtsstrukturen, Nachlassverwaltung und die Vorbereitung rechtlicher Dokumente.",
        service13: "Streitbeilegungshilfe",
        service14:
          "Im Falle eines finanziellen Streits bieten wir spezialisierte Unterstützung zur Lösung von Konflikten an. Wir bieten Beratung und Vertretung zum Schutz Ihrer finanziellen Interessen.",
      },
      contact: {
        contact1: "Kontakt",
        contact2: "Standort",
        contact3: "Rufen Sie uns an",
        contact4: "Senden Sie uns eine E-Mail",
        contact5: "Vorname und Nachname",
        contact6: "E-Mail",
        contact7: "Betreff",
        contact8: "Nachricht",
        contact9: "Verarbeitung...",
        contact10: "Ihre Nachricht wurde gesendet. Vielen Dank!",
        contact11: "Senden",
      },
      expert: {
        expert1: {
          nom: "Mawo KOKOU",
          titreMadal: "Nachricht des Direktors",
          fonction: "Direktor von DELATRUST",
          titre:
            "eine luxemburgische Buchhaltungs- und Steuerberatungsgesellschaft",
          detail:
            "<br /> DELATRUST, eine luxemburgische Buchhaltungs- und Steuerberatungsgesellschaft, zeichnet sich durch ihr innovatives Angebot in Buchhaltung, Steuerberatung sowie finanzieller und administrativer Verwaltung aus.<br />Als zertifizierter Buchhalter und Absolvent des Business Managements bringt Mawo tiefgehende Expertise und eine strategische Vision mit, insbesondere in Bezug auf die Erschließung und Nutzung wachsender Chancen auf afrikanischen Märkten. Unter seiner Leitung ist DELATRUST entschlossen, ihre internationale Präsenz zu stärken, indem maßgeschneiderte Lösungen angeboten werden, die den spezifischen Bedürfnissen von Unternehmen gerecht werden, die sich für die Märkte in Luxemburg und Afrika interessieren.<br /> Mit einem besonderen Fokus auf Innovation und Wertschöpfung verpflichtet sich Mawo, DELATRUST zu neuen Höhen zu führen, indem er qualitativ hochwertige Dienstleistungen für unsere Kunden bereitstellt und die vielen Möglichkeiten der internationalen Märkte, insbesondere der afrikanischen Märkte, erkundet. Dieser strategische Fokus wird es DELATRUST ermöglichen, eine Schlüsselrolle bei der Unterstützung von Unternehmen bei den Herausforderungen und Möglichkeiten eines schnell wachsenden Marktes zu spielen.<br /> Wir sind überzeugt, dass Mawo einen bedeutenden Beitrag zu unserem Unternehmen leisten und unser Team erfolgreich zu neuen Erfolgen führen wird.",
        },
      },
      cabinet: {
        cabinet1: {
          nom: "DELATRUST KANADA",
          dirigeant: "Leiter: Mawo KOKOU",
          adresse: "Adresse: ",
        },
        cabinet2: {
          nom: "DELATRUST FRANKREICH",
          dirigeant: "Leiter: Kokou TINO",
          adresse: "Adresse: ",
        },
        cabinet3: {
          nom: "DELATRUST BELGIEN",
          dirigeant: "Leiter: Mawo KOKOU",
          adresse: "Adresse: ",
        },
      },
      footer: {
        footer1: "Telefon",
        footer2: "E-Mail",
        footer3: "Nützliche Links",
        footer4: "Startseite",
        footer5: "Über uns",
        footer6: "Dienstleistungen",
        footer7: "Nutzungsbedingungen",
        footer8: "Datenschutz",
        footer9: "Betriebserlaubnis: 10170146/0",
      },
      info: [
        {
          title:
            "31. Dezember: Frist zur Einreichung der Steuererklärung für Einkommenssteuer, Gewerbesteuer und Vermögenssteuer der Gemeinden",
          content:
            "Denken Sie daran, dass die Steuererklärung bis spätestens zum 31. Dezember des auf das betreffende Steuerjahr folgenden Jahres beim zuständigen Finanzamt eingereicht werden muss. Verzögern Sie nicht die Vorbereitung Ihrer Unterlagen. Kontaktieren Sie uns, um mehr zu erfahren!",
        },
        {
          title: "31. Juli: Frist zur Einreichung des Jahresabschlusses",
          content:
            "Der Jahresabschluss muss innerhalb eines Monats nach seiner Genehmigung eingereicht werden, spätestens jedoch 7 Monate nach dem Ende des Geschäftsjahres für Einzelhändler oder nach Abschluss des Geschäftsjahres.",
        },
        {
          title:
            "Vor dem 1. März oder 1. Mai: Frist zur Einreichung der jährlichen Mehrwertsteuererklärung",
          content:
            "Die Frist zur Einreichung der jährlichen Mehrwertsteuererklärung hängt vom steuerfreien Gesamtumsatz ab, es sei denn, die Verwaltung der Registrierung und der Domänen (AED) trifft eine andere Entscheidung. Kontaktieren Sie uns, um mehr zu erfahren!",
        },
        {
          title: "Steuerliche Verpflichtungen in Luxemburg",
          content:
            "Die Gründung und Verwaltung eines Unternehmens in Luxemburg unterliegen verschiedenen Steuern und steuerlichen Verpflichtungen, die von drei Hauptverwaltungen geregelt werden: der Verwaltung der direkten Steuern (ACD), ",
        },
      ],
      
    },
  },
  FR: {
    message: {
      hello: "Bonjour",
      banner: {
        titre1: "Bienvenue à DELATRUST",
        titre2: "Cabinet d´expertise comptable au Luxembourg",
        pourquoi: "Pourquoi choisir DELATRUST?",
        plus: "Plus",
        pourquoi1:
          "DELATRUST est spécialisée dans la gestion financière, offrant des services tels que la comptabilité, l'audit, la fiscalité, et la gestion d'actifs avec une expertise reconnue.",
        apropos: "Conformité Réglementaire",
        apropos1:
          "En tant qu'entreprise fiduciaire, DELATRUST assure que toutes les opérations financières sont conformes aux lois et régulations en vigueur, minimisant ainsi les risques pour ses clients.",
        apropos2: "Conseils Personnalisés",
        apropos3:
          "DELATRUST offre des conseils personnalisés en fonction des besoins spécifiques de chaque client, qu'il s'agisse de particuliers, de PME, ou de grandes entreprises.",
        apropos4: "Fiabilité et Intégrité",
        apropos5:
          "DELATRUST est choisie pour sa fiabilité et son intégrité, des qualités essentielles dans la gestion de fonds et d'actifs pour le compte de clients.",
        apropos6: "Espace d'annonce",
      },
      menu: {
        menu1: "Accueil",
        menu2: "À propos",
        menu3: "Services",
        menu4: "Contact",
        menu5: "Espace client",
        menu6: {
          sousmenu1: "Qui sommes nous ?",
          sousmenu2: "Nos Objectifs",
          sousmenu3: "Nos experts",
          sousmenu4: "Nos cabinets",
        },
      },
      apropos: {
        apropos1: "À propos de nous",
        apropos2:
          "DELATRUST est un cabinet luxembourgeois innovant, spécialisé en expertise comptable et fiscale, ainsi qu'en gestion financière. Nous offrons notamment des solutions d'optimisation fiscale, de comptabilité, d'audit et de gestion d'actifs. Nous accordons une attention particulière à l'exploration et à la valorisation des opportunités d'affaires entre l'Europe et l'Afrique. Dans cette optique, nous nous engageons à fournir des solutions sur mesure, parfaitement adaptées aux besoins spécifiques de chacun de nos clients.",
          apropos3: "«Naviguez en toute confiance dans un environnement économique de plus en plus complexe, grâce à notre expertise et à nos solutions personnalisées!",
          valeur: {
          valeur: "Nos valeurs",
          valeur1: "Intégrité et Confidentialité ",
          valeur2:
            "Nous garantissons une éthique professionnelle irréprochable et protégeons rigoureusement la confidentialité des informations de nos clients.",
          valeur3: "Innovation et Excellence ",
          valeur4:
            "Nous intégrons l'innovation dans nos solutions tout en maintenant des standards élevés de qualité, en nous formant continuellement pour rester à jour.",
          valeur5: "Engagement et Service Client Personnalisé ",
          valeur6:
            "Nous offrons un accompagnement dévoué et réactif, avec des solutions sur mesure adaptées aux besoins spécifiques de chaque client.",
            valeur7: "Ouverture Internationale",
            valeur8:
              "Nous favorisons la collaboration internationale, notamment entre l'Europe et l'Afrique, afin de saisir des opportunités d'affaires globales",
            
          },
        mission: {
          mission: "Missions & Visions",
          mission1: "Expertise comptable, fiscale et optimisation",
          mission2:
            "Nous assurons la comptabilité, le conseil fiscal et proposons des stratégies d'optimisation fiscale sur mesure pour garantir la conformité et maximiser les avantages fiscaux de nos clients.",
          mission3: "Gestion financière et d'actifs ",
          mission4:
            "Nous offrons une gestion financière et administrative personnalisée, anticipons les risques, et optimisons la performance des entreprises, ainsi que la gestion et valorisation des actifs.",
          mission5: "Audit et certification ",
          mission6:
            "Nous réalisons des audits légaux et contractuels pour garantir la transparence et la fiabilité des états financiers",
            mission7: "Création, restructuration d'entreprises et internationalisation  ",
            mission8:
              "Nous accompagnons la création et la restructuration d'entreprises, en valorisant les opportunités d'affaires, notamment entre l'Europe et l'Afrique, pour favoriser des échanges économiques innovants.",
          
          },
      },
      service: {
        service1: "Nos services",
        service2: "",
        service3: "Comptabilité",
        service4:
          "Nous fournissons des services de comptabilité complets, incluant la gestion des écritures comptables, la préparation des états financiers, et la production de rapports financiers détaillés. Nous assurons une tenue de livres rigoureuse pour garantir une visibilité claire de vos finances.",
        service5: "Audit",
        service6:
          "Nos services d'audit sont conçus pour évaluer l'exactitude et la conformité de vos états financiers. Nous réalisons des audits internes et externes pour identifier les risques, améliorer les processus et assurer la transparence et la fiabilité de vos opérations financières.",
        service7: "Fiscalité",
        service8:
          "DELATRUST offre des services de conseil fiscal pour optimiser votre situation fiscale et garantir le respect des obligations fiscales. Nous vous aidons à planifier et à gérer vos déclarations fiscales, ainsi qu'à identifier des opportunités d'économies d'impôts.",
        service9: "Conseil Financier",
        service10:
          "Nos conseillers financiers fournissent des recommandations stratégiques pour améliorer votre santé financière. Nous vous assistons dans la planification financière, la gestion des risques, et le développement de stratégies pour atteindre vos objectifs financiers à long terme.",
        service11: "Services Juridiques et Fiduciaires",
        service12:
          "Nous offrons des services juridiques et fiduciaires pour gérer les aspects légaux et administratifs de vos affaires. Cela inclut la création de structures juridiques, la gestion des successions, et la préparation de documents légaux.",
        service13: "Assistance en Cas de Litige",
        service14:
          "En cas de litige financier, nous fournissons une assistance spécialisée pour vous aider à résoudre les différends. Nous offrons des conseils et une représentation pour protéger vos intérêts financiers.",
      },
      contact: {
        contact1: "Contact",
        contact2: "Emplacement",
        contact3: "Appelez-nous",
        contact4: "Envoyez-nous un e-mail",
        contact5: "Nom et prénoms",
        contact6: "Email",
        contact7: "Sujet",
        contact8: "Message",
        contact9: "Traitement...",
        contact10: "Votre message a été envoyé. Merci !",
        contact11: "Envoyer",
      },
      expert: {
        expert1: {
          nom: "Mawo KOKOU",
          titreMadal: "Mot du Directeur",
          fonction: "Directeur de DELATRUST",
          titre: "un cabinet luxembourgeois d’expertise comptable et fiscale",
          detail:
            " <br /> DELATRUST, un cabinet luxembourgeois d&rsquo;expertise comptable et fiscale. DELATRUST se distingue par son offre de services innovants en comptabilit&eacute;, fiscalit&eacute;, ainsi qu&rsquo;en gestion financi&egrave;re et administrative.<br />Expert-Comptable et dipl&ocirc;m&eacute; en Management des entreprises, Mawo rejoint DELATRUST avec une expertise approfondie et une vision strat&eacute;gique, notamment en ce qui concerne l'exploration et l'exploitation des opportunit&eacute;s croissantes sur les march&eacute;s africains. Sous sa direction, DELATRUST est d&eacute;termin&eacute; &agrave; renforcer sa pr&eacute;sence internationale en offrant des solutions sur mesure qui r&eacute;pondent aux besoins sp&eacute;cifiques des entreprises interess&eacute;es par les march&eacute;s luxembourgeois et africains.<br />Avec un accent particulier sur l&rsquo;innovation et la cr&eacute;ation de valeur, Mawo s&rsquo;engage &agrave; diriger DELATRUST vers de nouveaux horizons, en fournissant des services de qualit&eacute; sup&eacute;rieure &agrave; nos clients tout en explorant les nombreuses opportunit&eacute;s qu&rsquo;offre les march&eacute;s internationaux, en particulier les march&eacute;s africains. Ce focus strat&eacute;gique permettra &agrave; DELATRUST de jouer un r&ocirc;le cl&eacute; dans l&rsquo;accompagnement des entreprises face aux d&eacute;fis et aux possibilit&eacute;s d&rsquo;un march&eacute; en pleine expansion.<br />Nous sommes convaincus que Mawo apportera une contribution significative &agrave; notre cabinet et qu'il m&egrave;nera notre &eacute;quipe avec succ&egrave;s vers de nouvelles r&eacute;ussites.",
        },
      },
      cabinet: {
        cabinet1: {
          nom: "DELATRUST CANADA",
          dirigeant: "Gérant : Mawo KOKOU",
          adresse: "Adresse : ",
        },
        cabinet2: {
          nom: "DELATRUST FRANCE",
          dirigeant: "Gérant : Kokou TINO",
          adresse: "Adresse : ",
        },
        cabinet3: {
          nom: "DELATRUST BELGIQUE",
          dirigeant: "Gérant : Mawo KOKOU",
          adresse: "Adresse : ",
        },
      },
      footer: {
        footer1: "Téléphone",
        footer2: "Email",
        footer3: "Liens utiles",
        footer4: "Accueil",
        footer5: "À propos de nous",
        footer6: "Services",
        footer7: "Conditions d'utilisation",
        footer8: "Politique de confidentialité",
        footer9: "Autorisation d´établissement: 10170146/0",
      },
      info: [
        {
          title:
            "31 décembre : Délai de dépôt de la Déclaration pour l'impôt sur le revenu, l'impôt commercial et l'impôt sur la fortune des collectivités",
          content:
            "N'oubliez pas que la déclaration fiscale doit être soumise au bureau d'imposition compétent avant le 31 décembre de l'année suivant l'année d'imposition concernée. Ne tardez pas à préparer vos documents. Contactez-nous pour en savoir plus ! ",
        },
        {
          title: "31 juillet : Délai de dépôt des comptes annuels  ",
          content:
            "Les comptes annuels doivent être déposés dans le mois suivant leur approbation, soit au plus tard 7 mois après la clôture de l’année civile pour les commerçants personnes physiques, ou après la clôture de ",
        },
        {
          title:
            "Avant le 1er mars ou le 1er mai : Délai de dépôt de la déclaration TVA annuelle  ",
          content:
            "Le délai de dépôt de la déclaration de TVA annuelle dépend du chiffre d’affaires global hors taxe, à moins qu'une autre décision soit prise par l'Administration de l'Enregistrement et des Domaines (AED). Contactez-nous pour en savoir plus !",
        },
        {
          title: "Obligations fiscales au Luxembourg  ",
          content:
            "La création et la gestion d’une entreprise au Luxembourg sont soumises à diverses taxes et obligations fiscales, régies par trois administrations principales : l'Administration des Contributions Directes (ACD), ",
        },
      ],
    },
  },
};
