import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import LoadScript from "vue-plugin-load-script";
import VueI18n from 'vue-i18n'
import { langues } from './langue/langue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap and BootstrapVue CSS files (order is important)
// Ligne 535 mets le en commentaire
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueMarqueeSlider from 'vue-marquee-slider';
Vue.use(VueMarqueeSlider)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueI18n)
Vue.config.productionTip = false
Vue.use(LoadScript);


// Liste des langues supportées
const languesSupportees = ['EN', 'FR', 'DE']; // Ajouter les langues que votre application supporte

// Vérifie si une langue est déjà enregistrée dans le navigateur
const savedLangue = localStorage.getItem('langue');
// Si aucune langue n'est enregistrée ou si elle n'est pas supportée, détecte la langue du navigateur
let defaultLangue = savedLangue && languesSupportees.includes(savedLangue) 
                    ? savedLangue 
                    : navigator.language.substr(0, 2).toUpperCase();

// Si la langue du navigateur n'est pas supportée, utilise une langue par défaut
if (!languesSupportees.includes(defaultLangue)) {
  defaultLangue = 'EN'; // Définir la langue par défaut, par exemple 'EN' pour l'anglais
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: defaultLangue, // set locale
  messages : langues, // set locale messages
  
})

console.log(langues)
new Vue({
  router,
  store ,
    i18n ,
  render: h => h(App),
  mounted() {

    this.$nextTick(async () => {
      // await Vue.loadScript("/delatrust/assets/js/main.js")
      // await Vue.loadScript("/delatrust/assets/vendor/bootstrap/js/bootstrap.bundle.min.js")
      // await Vue.loadScript("/delatrust/assets/vendor/php-email-form/validate.js")
      // await Vue.loadScript("/delatrust/assets/vendor/aos/aos.js")
      // await Vue.loadScript("/delatrust/assets/vendor/glightbox/js/glightbox.min.js")
      // await Vue.loadScript("/delatrust/assets/vendor/purecounter/purecounter_vanilla.js")
      // await Vue.loadScript("/delatrust/assets/vendor/swiper/swiper-bundle.min.js")

      // a utiliser si c'est delatrust.lu
      await Vue.loadScript("/assets/js/main.js")
      await Vue.loadScript("/assets/vendor/bootstrap/js/bootstrap.bundle.min.js")
      await Vue.loadScript("/assets/vendor/php-email-form/validate.js")
      await Vue.loadScript("/assets/vendor/aos/aos.js")
      await Vue.loadScript("/assets/vendor/glightbox/js/glightbox.min.js")
      await Vue.loadScript("/assets/vendor/purecounter/purecounter_vanilla.js")
      await Vue.loadScript("/assets/vendor/swiper/swiper-bundle.min.js")
      
      
    })
  }
  
}).$mount('#app')
